<template>
   <nav class="main-header navbar navbar-expand navbar-white navbar-light bg-gradient-gray">
      <!-- Left navbar links -->
      <ul v-if="userProfile !== 'client'" class="navbar-nav">
         <li class="nav-item">
            <router-link class="nav-link" data-widget="pushmenu" to="#" role="button"><i class="fas fa-bars"></i></router-link>
         </li>
      </ul>

      <!-- Right navbar links -->
      <ul class="navbar-nav ml-auto">
         <li class="nav-item dropdown">
            <router-link class="nav-link" data-toggle="dropdown" to="#">
               <i>
                  <div class="media pb-3">
                     <a class="user-picture" href="/" @click.prevent>
                        <img
                           v-if="userPhotoUrl"
                           :src="userPhotoUrl"
                           :alt="userName"
                        >
                        <div v-else class="uk-border-circle" style="background-color: #FFFFFF; border-radius: 50%">
                           {{ nameInitials }}
                        </div>
                     </a>
                  </div>
               </i>
            </router-link>
            <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
               <router-link to="#" class="dropdown-item">
                  <!-- Message Start -->
                  <div class="media">
                     <div class="media-body">
                        <h3 class="dropdown-item-title text-center">
                           <router-link to="/profile" class="text-center">Perfil</router-link>
                        </h3>
                     </div>
                  </div>
                  <!-- Message End -->
               </router-link>
               <div class="dropdown-divider"></div>
               <router-link to="#" class="dropdown-item">
                  <!-- Message Start -->
                  <div class="media">
                     <div class="media-body">
                        <h3 class="dropdown-item-title text-center">
                           <p @click.prevent="logout" class="text-center">Sair</p>
                        </h3>
                     </div>
                  </div>
                  <!-- Message End -->
               </router-link>
               <div class="dropdown-divider"></div>
            </div>
         </li>
      </ul>
   </nav>
</template>

<script>
import store from '@/store';
import { mapState } from "vuex";
import userService from "../../modules/dashboard/modules/user/services/user-service";

export default {
   name: "Navbar",
   data () {
      return {
         userPhotoUrl: null,
         nameInitials: ''
      }
   },
   computed: {
      ...mapState({
         userProfile: state => state.auth.user.data.profile,
         userName: state => state.auth.user.data.name,
         userId: state => state.auth.user.data.userId,
      })
   },
   async created() {
      this.nameInitials = this.getInitials(this.userName);
      try {
         const { data: response} = await userService.getPhotoUrl(this.userId);
         this.userPhotoUrl = response;
      }catch (e) {}
   },
   methods: {
      getInitials(name) {
         if (!name) return "";
         const words = name.trim().split(" ");
         const first = words.shift();
         let last = words.pop();
         if (!last) last = first;
         if (first === last) return first.charAt(0).toUpperCase();
         return first.charAt(0).toUpperCase() + last.charAt(0).toUpperCase();
      },
      logout () {
         try {
            this.$router.push({ name: 'login' });
            setTimeout(() => {
               store.dispatch('logout');
               window.location.reload();
            }, 200)
         } catch (e) {}
      }
   }
}
</script>

<style scoped>
.user-picture,
.user-picture > * {
   display: flex !important;
   align-items: center;
   justify-content: center;
   border-radius: 50%;
}

.user-picture > * {
   width: 2rem;
   height: 2rem;
}
</style>

