<template>
   <aside class="main-sidebar sidebar-light-primary elevation-4">
      <!-- Brand Logo -->
      <router-link to="/dashboard" class="brand-link bg-gradient-gray">
         <img src="/static/dist/img/longavida.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3" style="opacity: .8">
         <span class="brand-text font-weight-light "> <strong>Vida Longa</strong> </span>
      </router-link>

      <!-- Sidebar -->
      <div class="sidebar">

         <!-- Sidebar Menu -->
         <nav class="mt-2">
            <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">

               <li
                  class="nav-item"
                  v-for="(route, index) in visibleRoutes"
                  :key="route.name"
               >
                  <router-link
                     v-if="route.subRoutes.length == 0"
                     :to="{ name: route.name }"
                     :class="[$route.name === route.name ? 'nav-link bg-gradient-gray' : 'nav-link']"
                  >
                     <i v-if="route.name !== 'clients'" :class="`nav-icon fas ${route.icon}`"></i>
                     <svg v-else class="mr-2" xmlns="http://www.w3.org/2000/svg" height="20" width="24" viewBox="0 0 640 512">
                        <path d="M96 0C43 0 0 43 0 96V416c0 53 43 96 96 96H544c53 0 96-43 96-96V96c0-53-43-96-96-96H96zM64 96c0-17.7 14.3-32 32-32H544c17.7 0 32 14.3 32 32V416c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V96zm159.8 80a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM96 309.3c0 14.7 11.9 26.7 26.7 26.7h56.1c8-34.1 32.8-61.7 65.2-73.6c-7.5-4.1-16.2-6.4-25.3-6.4H149.3C119.9 256 96 279.9 96 309.3zM461.2 336h56.1c14.7 0 26.7-11.9 26.7-26.7c0-29.5-23.9-53.3-53.3-53.3H421.3c-9.2 0-17.8 2.3-25.3 6.4c32.4 11.9 57.2 39.5 65.2 73.6zM372 289c-3.9-.7-7.9-1-12-1H280c-4.1 0-8.1 .3-12 1c-26 4.4-47.3 22.7-55.9 47c-2.7 7.5-4.1 15.6-4.1 24c0 13.3 10.7 24 24 24H408c13.3 0 24-10.7 24-24c0-8.4-1.4-16.5-4.1-24c-8.6-24.3-29.9-42.6-55.9-47zM512 176a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM320 256a64 64 0 1 0 0-128 64 64 0 1 0 0 128z"/>
                     </svg>
                     <p>{{ $t(`${route.to}`) }}</p>
                  </router-link>

                  <a href="#" class="nav-link" v-if="route.subRoutes.length > 0">
                     <i v-if="route.name !== 'clients'" :class="`nav-icon fas ${route.icon}`"></i>
                     <p class="">
                        {{ $t(`${route.to}`) }}
                     </p>
                  </a>
                  <ul
                     class="nav nav-treeview"
                     v-for="subRoute in route.subRoutes"
                     v-if="route.subRoutes.length > 0"
                  >
                     <li class="nav-item ml-2">
                        <router-link
                           :to="{ name: subRoute.to }"
                           :class="[$route.name === subRoute.to ? 'nav-link bg-gradient-gray' : 'nav-link']"
                           style="color: inherit;"
                        >
                           <i :class="`nav-icon fas ${subRoute.icon}`"></i>
                           <p>{{ $t(`${subRoute.name}`) }}</p>
                        </router-link>
                     </li>
                  </ul>

                  <hr v-if="index === 0 && userProfile === 'admin'">
               </li>

            </ul>
         </nav>
         <!-- /.sidebar-menu -->
      </div>
      <!-- /.sidebar -->
   </aside>
</template>

<script>
import { mapState, mapActions } from "vuex"
import { EMPLOYEE_ALLOWED_ROUTES, PARTNER_ALLOWED_ROUTES } from "../../utils/roles"

export default {
   name: "Sidebar",
   data() {
      return {
         routes: [
            { name: 'dashReports', to: 'dashboard', icon: 'fa-tachometer-alt', subRoutes: [] },
            { name: 'articles', to: 'articles', icon: 'fa-file-invoice', subRoutes: [] },
            { name: 'benefits', to: 'benefits', icon: 'fa-box', subRoutes: [] },
            { name: 'categories', to: 'categories', icon: 'fa-layer-group',
               subRoutes: [
                  { name: 'article', to: 'categories', icon: 'fa-file-invoice'},
                  { name: 'branch_of_activity', to: 'branches', icon: 'fa-building'},
               ]
            },
            { name: 'clients', to: 'clients', icon: 'fa-user-circle', subRoutes: [] },
            { name: 'coupons', to: 'coupons', icon: 'fa-tags', subRoutes: [] },
            { name: 'partnerCompanies', to: 'partner_companies', icon: 'fa-building', subRoutes: [] },
            { name: 'users', to: 'users', icon: 'fa-users', subRoutes: [] },
            { name: 'questions', to: 'questions', icon: 'fa-question', subRoutes: [] }
         ]
      }
   },
   computed: {
      ...mapState({
         userProfile: state => state.auth.user.data.profile
      }),
      visibleRoutes () {
         let allowedRoutes = [];

         switch (this.userProfile) {
            case 'admin':
               return this.routes;
               break;

            case 'employee':
               this.routes.forEach((route) => {
                  const result = EMPLOYEE_ALLOWED_ROUTES.find((routeName) => routeName === route.name);

                  if (result !== undefined)
                     allowedRoutes.push(route);
               });
               return allowedRoutes;
               break;

            case 'partner':
               this.routes.forEach((route) => {
                  const result = PARTNER_ALLOWED_ROUTES.find((routeName) => routeName === route.name);

                  if (result !== undefined)
                     allowedRoutes.push(route);
               });
               return allowedRoutes;
         }
      }
   },
   async created() {
      if (this.userProfile === 'admin' || this.userProfile === 'employee')
         await this.getAllCategories();
   },
   methods: {
      ...mapActions(['getAllCategories'])
   }
}
</script>

<style>
a:hover {
   color: inherit;
   text-decoration: none;
}
</style>



